<template>
  <Base title="其他文保单位清单" titleIcon="el-icon-s-claim">
  <div>
    <div class="search-row">
      <el-input placeholder="名称" v-model="filter.keyword" style="width: 500px">
        <template #prepend> 关键字 </template>
      </el-input>
      <el-button type="primary" style="margin-left: 5px" @click="
        () => {
          filter.pageNum = 1;
          query();
        }
      ">查询</el-button>
    </div>
  </div>

  <el-table :data="pagerData.list" v-loading="isLoading">
    <el-table-column label="单位编号" prop="heritage_Code"></el-table-column>
    <el-table-column label="单位名称" prop="heritage_Name"></el-table-column>
    <el-table-column label="所在市县">
      <template #default="{ row: item }">
        {{ item.city_Name }}
        {{ item.county_Name }}
      </template>
    </el-table-column>
    <el-table-column label="级别" prop="heritageLevel_Name"></el-table-column>
    <!-- <el-table-column label="批次" prop="heritageBatch_Name"></el-table-column>
      <el-table-column
        label="公布类型"
        prop="heritageClass_Name"
      ></el-table-column> -->
    <el-table-column label="机构类型" prop="institute_Type_Name"></el-table-column>
    <!-- <el-table-column
        label="类型明细"
        prop="heritageType_Name"
      ></el-table-column>
      <el-table-column
        label="公布时代"
        prop="heritageAge_Name"
      ></el-table-column> -->
    <!-- <el-table-column label="操作"></el-table-column> -->
    <el-table-column label="操作" v-if="user.inRoles(roles.prov)">
      <template #default="{ row: item }">
        <el-button icon="el-icon-edit" type="text"
          @click="() => $router.push(`/HeritageUnit/BaseManage/${item.id}?redirect=/HeritageUnit/else`)">修改
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination style="padding-left: 5px" @current-change="handleCurrentChange" @size-change="handleSizeChange"
    v-model:page-size="filter.pageSize" :total="pagerData.count" layout="sizes,total, prev, pager, next"
    v-model:currentPage="filter.pageNum"></el-pagination>
  </Base>
</template>

<script lang="ts">
import roles from "@/types/roles.ts";
import { defineComponent, inject, ref, onMounted, reactive, toRefs } from "vue";
import { useQueryHeritages } from "@/network/heritageUnit.ts";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base },
  setup() {
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      keyword: null,
      isUnit: false,
    });

    const [isLoading, pagerData, query] = useQueryHeritages(filter);
    onMounted(() => {
      query();
    });
    return {
      user,
      roles,
      isLoading,
      pagerData,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
</script>

<style scoped>
</style>