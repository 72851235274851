
import roles from "@/types/roles.ts";
import { defineComponent, inject, ref, onMounted, reactive, toRefs } from "vue";
import { useQueryHeritages } from "@/network/heritageUnit.ts";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base },
  setup() {
    const user: any = inject("user");
    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      keyword: null,
      isUnit: false,
    });

    const [isLoading, pagerData, query] = useQueryHeritages(filter);
    onMounted(() => {
      query();
    });
    return {
      user,
      roles,
      isLoading,
      pagerData,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
